<script setup>
import AuthenticationCard from '@/Components/AuthenticationCard.vue';
import AuthenticationCardLogo from '@/Components/AuthenticationCardLogo.vue';
import Checkbox from '@/Components/Checkbox.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import Socialstream from '@/Components/Socialstream.vue';
import TextInput from '@/Components/TextInput.vue';
import {Head, Link, useForm} from '@inertiajs/vue3';
import {browserSupportsWebAuthn, startAuthentication} from '@simplewebauthn/browser';
import {ref} from 'vue';

defineProps({
    canResetPassword: Boolean,
    status: String,
});

const showPasswordField = ref(!browserSupportsWebAuthn());

const form = useForm({
    email: '',
    password: '',
    remember: false,
    answer: null,
});

const submit = async () => {
    if (showPasswordField.value) {
        form.transform(data => ({
            ...data,
            remember: form.remember ? 'on' : '',
        })).post(route('login'), {
            onFinish: () => form.reset('password'),
        });

        return;
    }

    let answer;
    try {
        const options = await axios.get(route('api.v1.passkeys.authenticateOptions'), {
            params: { email: form.email },
        });
        answer = await startAuthentication(options.data);
    } catch (e) {
        if (!showPasswordField.value) {
            showPasswordField.value = true;
        }

        return;
    }

    form.answer = JSON.stringify(answer);
    form.post(route('passkeys.authenticate'), {
        onError: () => showPasswordField.value = true,
        onFinish: () => form.reset('password'),
    });
};
</script>

<template>
    <Head :title="$t('Log in')" />

    <AuthenticationCard>
        <template #logo>
            <AuthenticationCardLogo/>
        </template>

        <div v-if="status" class="mb-4 font-medium text-sm text-green-600 dark:text-green-400">
            {{ status }}
        </div>

        <form @submit.prevent="submit">
            <div>
                <InputLabel for="email" :value="$t('Email')" />
                <TextInput
                    id="email"
                    v-model="form.email"
                    autofocus
                    class="mt-1 block w-full"
                    required
                    type="email"
                    autocomplete="username"
                />
                <InputError :message="form.errors.email" class="mt-2"/>
            </div>

            <div v-if="showPasswordField"
                 class="mt-4">
                <InputLabel for="password" :value="$t('Password')" />
                <TextInput
                    id="password"
                    v-model="form.password"
                    autocomplete="current-password"
                    class="mt-1 block w-full"
                    required
                    type="password"
                />
                <InputError :message="form.errors.password" class="mt-2"/>
            </div>

            <div class="block mt-4">
                <label class="flex items-center">
                    <Checkbox v-model:checked="form.remember" name="remember"/>
                    <span class="ml-2 text-sm text-gray-600 dark:text-gray-400">{{ $t('Remember me')}}</span>
                </label>
            </div>

            <div class="flex items-center justify-end mt-4">
                <Link v-if="canResetPassword && showPasswordField" :href="route('password.request')" class="underline text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-gray-800">
                    {{ $t('Forgot your password?')}}
                </Link>

                <PrimaryButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing" class="ml-4">
                    {{ $t('Log in')}}
                </PrimaryButton>
            </div>
        </form>

        <Socialstream v-if="$page.props.socialstream.show && $page.props.socialstream.providers.length"
                      :error="$page.props.errors.socialstream" :prompt="$page.props.socialstream.prompt"
                      :labels="$page.props.socialstream.labels" :providers="$page.props.socialstream.providers"/>
    </AuthenticationCard>
</template>
